<template>
  <div class="cadastr-object-panel">
    <div
      class="close-button cadastr_close_button"
      @click="$emit('close-cadastr-object-panel')"
    >
      <div class="el-icon-close"></div>
    </div>
    <el-form class="cadastr_object_form">
      <template v-if="Object.keys(area).length > 0">
        Участок<br>
        <el-form-item label="Кадастровый номер">
          {{ area.cn }}
        </el-form-item>
        <el-form-item label="Категория">
          {{ area.category_type }}
        </el-form-item>
        <el-form-item label="Адрес">
          {{ area.address }}
        </el-form-item>
        <el-form-item label="Площадь кв.м.">
          {{ area.area_value }}
        </el-form-item>
        <el-form-item label="Кадастровая стоимость в руб.">
          {{ area.cad_cost }}
        </el-form-item>
      </template>
      <template v-if="Object.keys(building).length > 0">
        Строение<br>
        <el-form-item label="Кадастровый номер">
          {{ building.cn }}
        </el-form-item>
        <el-form-item label="Наименование">
          {{ building.name }}
        </el-form-item>
        <el-form-item label="Назначение">
          {{ building.purpose }}
        </el-form-item>
        <el-form-item label="Адрес">
          {{ building.address }}
        </el-form-item>
        <el-form-item label="Дата постройки">
          {{ building.year_built }}
        </el-form-item>
        <el-form-item label="Площадь кв.м.">
          {{ building.area_value }}
        </el-form-item>
        <el-form-item label="Кадастровая стоимость в руб.">
          {{ building.cad_cost }}
        </el-form-item>
        <el-form-item label="Этажность">
          {{ building.floors }}
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>

<script>
  export default {
    name: 'CadastrObjectPanel',
    props: {
      building: {
        type: Object,
        default: function () {
          return {}
        }
      },
      area: {
        type: Object,
        default: function () {
          return {}
        }
      }
    }
  }
</script>

<style scoped>
.cadastr-object-panel {
  width: 300px;
  border-radius: 6px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16), 0px 2px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 10px;
  top: 68px;
  z-index: 999;
  background-color: white;
  height: 410px;
  overflow-y: overlay;
}
.cadastr_object_form {
  padding: 10px 20px 10px 10px;
}
.cadastr_object_form /deep/ .el-form-item__label {
  line-height: 30px;
}
.cadastr_object_form /deep/ .el-form-item__content {
  line-height: 30px;
}
.cadastr_object_form /deep/ .el-form-item {
  border-bottom: gray 1px solid;
}
.cadastr_close_button {
  margin: 1px 0 0 10px;
  position: absolute;
  right: 25px;
  top: 5px;
  cursor: pointer;
}
</style>
