import {AxiosResponse} from 'axios'
import {APIRequest} from '@/core/infrastructure/api/APIRequest';
import {HTTPMethod} from '@/core/infrastructure/api/APIClient';

export namespace MapEditorAPI {
  export class GetCoordinateSystemsByUserId implements APIRequest<string> {
    response: string;
    path = '/mapeditor/coordinate_systems_user/';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;

    constructor(userId: number) {
      this.path += userId
    }
  }

  export class GetCoordinateSystemBySrid implements APIRequest<string> {
    response: string;
    path = '/mapeditor/coordinate_systems/';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;

    constructor(srid: number) {
      this.path += srid
    }
  }
}
