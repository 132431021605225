<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="showWindow"
    :title="getTitle"
    >
    <enter-coordinates
      v-if="showWindowMode === positionOnModes.COORDINATES"
      :user-c-ss="this.userCSs"
      @change-coordinates="changeCoordinatesHandler"
      @change-cs="changeCSHandler"
      ></enter-coordinates>
    <enter-cadastral-number
      ref="cadastral_number"
      v-if="showWindowMode === positionOnModes.CADASTRAL_NUMBER"
      @change-coordinates="changeCoordinatesHandler"
      @change-cs="changeCSHandler"
      @loading-show="changeIsLoading(true)"
      @loading-hide="changeIsLoading(false)"
    ></enter-cadastral-number>
    <enter-address
      v-if="showWindowMode === positionOnModes.ADDRESS"
      :default-value="addressSettings.addressPrefix"
      :address="addressSettings.valueByDefault"
      @change-coordinates="changeCoordinatesHandler"
      @change-cs="changeCSHandler"
    ></enter-address>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showWindow = false">Отмена</el-button>
      <el-button type="primary" @click="apply" :loading="isLoading">Перейти</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import EnterAddress from './enterAddress'
  import EnterCoordinates from './enterCoordinates'
  import EnterCadastralNumber from './enterCadastralNumber'

  const POSITION_ON_MODE = {
    COORDINATES: 'coordinates',
    CADASTRAL_NUMBER: 'cadastral_number',
    ADDRESS: 'address',
    NONE: null
  }

  export default {
    name: 'PositionOn',
    components: { EnterAddress, EnterCoordinates, EnterCadastralNumber },
    props: {
      userCSs: {
        type: Array,
        default: function () {
          return []
        }
      },
      addressSettings: {
        type: Object,
        default: function () {
          return {
            addressPrefix: '',
            valueByDefault: ''
          }
        }
      }
    },
    computed: {
      getTitle: function () {
        switch (this.showWindowMode) {
          case POSITION_ON_MODE.COORDINATES:
            return 'Введите координаты'
          case POSITION_ON_MODE.ADDRESS:
            return 'Введите адрес'
          case POSITION_ON_MODE.CADASTRAL_NUMBER:
            return 'Введите кадастровый номер'
          default:
            return ''
        }
      }
    },
    data: function () {
      return {
        showWindowMode: POSITION_ON_MODE.NONE,
        showWindow: false,
        coordinates: {
          x: null,
          y: null
        },
        cs: null,
        positionOnModes: POSITION_ON_MODE,
        isLoading: false
      }
    },
    watch: {
      showWindow: {
        handler: function (newValue) {
          if (!newValue) {
            this.showWindowMode = POSITION_ON_MODE.NONE
          }
        }
      },
      showWindowMode: {
        handler: function (newValue) {
          this.showWindow = newValue !== POSITION_ON_MODE.NONE
        }
      }
    },
    methods: {
      changeIsLoading (value) {
        this.isLoading = value
      },
      changeCoordinatesHandler (coordinates) {
        this.coordinates = coordinates
      },
      changeCSHandler (cs) {
        this.cs = cs
      },
      show (type) {
        if (Object.values(POSITION_ON_MODE).includes(type)) {
          this.showWindowMode = type
        }
      },
      resetForm() {
        this.cs = null
        this.coordinates = { x: null, y: null }
        this.showWindow = false
      },
      async apply () {
        if (this.showWindowMode === POSITION_ON_MODE.CADASTRAL_NUMBER) {
          await this.$refs['cadastral_number'].findByCadastralNumber()
        }
        if (this.coordinates.x !== null
            && this.coordinates.y !== null
            && this.cs !== null) {
          this.$emit('position-on', this.coordinates, this.cs)
          this.resetForm()
        } else {
          console.warn('empty coordinates or cs')
        }
      }
    }
  }
</script>

<style scoped>

</style>
